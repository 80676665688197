import ky from "ky";

export const sendUserStatusToServer = async (user, isActive) => {
  const formData = new FormData();
  formData.append("uuid", user.uuid);
  formData.append("id", user.id);
  formData.append("active", isActive);
  formData.append("updated", new Date().toISOString());
  const status = isActive ? "online" : "offline";

  const data = {
    id: user?.uuid,
    name: user?.fullName,
    externalId: user?.id,
    profileUrl: `/profile/${user?.id}`,
    email: user?.email,
    status,
  };

  try {
    await ky.post("/resources/friend", {
      body: formData,
    });
    await ky.patch(
      `https://ps.pndsn.com/v2/objects/${user?.subscribeKey}/uuids/${user?.uuid}?include=status`,
      {
        json: data,
      }
    );
    console.log(`User ${user.id} status updated to ${isActive ? "active" : "inactive"}`);
  } catch (error) {
    console.error("Failed to send user status to server:", error);
  }
};

export const sendViewsToServer = async (json) => {
  const formData = new FormData();
  formData.append("json", json);

  try {
    await ky.post("/resources/feeds", {
      body: formData,
    });
  } catch (error) {
    console.error("Failed to send user status to server:", error);
  }
};
